// //衝浪者
@keyframes scroll {
  from {
    bottom: 0
  }

  to {
    bottom: 10px;
  }
}

// //kv hover漸大
// @keyframes gogo {
//   from {
//     background-size: 100%;
//   }

//   to {
//     background-size: 200%;
//   }
// }

// //衝浪上方文案fade out
// @keyframes slide-words {
//   0% {
//     // top: 0;

//     opacity: 0;
//     letter-spacing: 20px;
//   }

//   5% {
//     // top: 0;
//     opacity: 0;
//   }

//   20% {
//     // top: 0;
//     opacity: 1;
//   }

//   40% {
//     // top: -30px;
//     opacity: 0;
//     letter-spacing: 3px;
//   }
// }

// //767以下
// @keyframes slide-words2 {
//   0% {
//     opacity: 0;
//     letter-spacing: 7px;
//   }

//   5% {
//     opacity: 0;
//   }

//   20% {
//     opacity: 1;
//   }

//   30% {
//     opacity: 0;
//     letter-spacing: 3px;
//   }
// }

// //動態展示按鈕繃跳
// @keyframes zoomblock {
//   0% {
//     width: 100%;
//     height: 100%;
//   }

//   5% {
//     width: 125%;
//     height: 125%;
//     background-color: #124c8d;
//   }

//   10% {
//     width: 90%;
//     height: 90%;
//   }

//   15% {
//     width: 120%;
//     height: 120%;
//   }

//   20% {
//     width: 100%;
//     height: 100%;
//   }
// }

// @keyframes zoomblock_svg {
//   0% {
//     color: #fff;
//   }

//   50% {
//     color: $third-color;

//   }

//   100% {
//     color: #fff;

//   }
// }