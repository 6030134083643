@charset "utf-8";
@import "var.scss";

.wrap {
  footer {
    padding: 20px 0;
    background-color: #0e1905;
    text-align: center;
    color: #fff;
    letter-spacing: 1px;

  }

  ////////////////////////////////////////////////////////////

  @include breakpoint(960) {
    footer {}
  }

  ////////////////////////////////////////////////////////////

  @include breakpoint(1280) {}
}