@charset "utf-8";

$a: rgb(253, 209, 209);
$b: #dac492;
$c: #f8f361;
$d: #caffbf;
$e: #27b8d1;
$f: #4fba94;
$g: #dcbef2;

.cc1 {
  background-color: #bcd79d;
}

.cc2 {
  background-color: #f3cc99;
}

.cc3 {
  background-color: #f3f099;
}

.cc4 {
  background-color: #c7d94f;
}

.cc5 {
  background-color: #63d94f;
}

.cc6 {
  background-color: #4fd9b4;
}