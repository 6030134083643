@charset "utf-8";

html,
body {
	font-family: 'Noto Sans CJK TC', sans-serif, "微軟正黑體", "新細明體", 'Roboto Mono', monospace, Arial;
	//background-color: #e0e0e0;

	a {}
}



:focus {
	outline: none;
}

//
.m0-a {
	margin: 0 auto;
}

.mr0 {
	margin-right: 0;
}

.mt5 {
	margin-top: 5px !important;
}

.mt10 {
	margin-top: 10px !important;
}

.mt20 {
	margin-top: 20px !important;
}

.mt30 {
	margin-top: 30px !important;
}

.mt40 {
	margin-top: 40px !important;
}

.mt50 {
	margin-top: 50px !important;
}

.mt60 {
	margin-top: 60px !important;
}

.mt70 {
	margin-top: 70px !important;
}

.mt80 {
	margin-top: 80px !important;
}

.mt90 {
	margin-top: 90px !important;
}

.mt100 {
	margin-top: 100px !important;
}

//
.mb10 {
	margin-bottom: 10px !important;
}

.mb20 {
	margin-bottom: 20px !important;
}

.mb30 {
	margin-bottom: 30px !important;
}

.mb40 {
	margin-bottom: 40px !important;
}

.mb50 {
	margin-bottom: 50px !important;
}

.mb60 {
	margin-bottom: 60px !important;
}

.mb70 {
	margin-bottom: 70px !important;
}

.mb80 {
	margin-bottom: 80px !important;
}

.mb90 {
	margin-bottom: 90px !important;
}

.mb100 {
	margin-bottom: 100px !important;
}

//
.w10 {
	width: 10% !important;
}

.w20 {
	width: 20% !important;
}

.w30 {
	width: 30% !important;
}

.w40 {
	width: 40% !important;
}

.w50 {
	width: 50% !important;
}

.w60 {
	width: 60% !important;
}

.w70 {
	width: 70% !important;
}

.w80 {
	width: 80% !important;
}

.w90 {
	width: 90% !important;
}

.w100 {
	width: 100% !important;
}

.p-tb20 {
	padding: 20px 0;
}

.p5 {
	padding: 5px;
}

.p10 {
	padding: 10px;
}

.p20 {
	padding: 20px;
}

.p30 {
	padding: 30px;
}

.p40 {
	padding: 40px;
}

.tc {
	text-align: center;
}

.pr-0 {
	padding-right: 0px;
}

.pl-0 {
	padding-left: 0px;
}

.mr-l-0 {
	margin-right: 0px;
	margin-left: 0px;
}

.pr-pl-0 {
	padding-right: 0px;
	padding-left: 0px;
}

//
.clearfix2 {
	&:before {
		content: "";
		display: table;
	}

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	zoom: 1;
	clear: both;
	float: none;
}

.db {
	display: block;
}