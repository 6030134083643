@charset "utf-8";
@import "style.scss";
@import "nav";
@import "footer";
@import "form";
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&family=Rowdies:wght@700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@500;600;700&family=Rowdies:wght@700&display=swap');


html,
body {
  //overflow-y: hidden;
  // font-family: 'Noto Sans TC', sans-serif;
  // font-size: 16px;
}

* {
  //transition: .3s;
}

.wrap {
  //background-color: rgb(209, 170, 170);


  .box {
    .main {

      .kv {
        // background: red url('../images/kv-m.jpg') no-repeat center 0;
        // background-size: cover;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Lato', sans-serif;

        // font-family: 'Raleway', sans-serif;
        // font-family: 'Roboto', sans-serif;
        &:before {
          content: '';
          color: white;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background: #000 url('../images/kv-m.jpg') no-repeat 0 0;
          background-size: 100%;
        }

        section {
          position: relative;
          z-index: 2;

          .logo2 {
            width: 70%;
            max-width: 400px;
            margin: 0 auto;
            filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .6));
          }

          p {
            text-align: center;
            color: #fff;
            font-size: 7vw;
            line-height: 1.2em;
          }
        }
      }

      .content {
        position: relative;
        z-index: 10;
        //content下的主容器
        // &>div {
        //   width: 90%;
        //   margin: 0 auto;
        // }
        background-color: #000;

        .b1 {
          background: #000 url('../images/block1_bg-m.jpg') no-repeat center 0;
          background-size: cover;
          padding: 10% 0;

          .block-box {
            width: 90%;
            max-width: 1600px;
            margin: 0 auto;
            // outline: 1px solid green;
          }

          section {
            width: 90%;
            margin: 0 auto;
            // outline: 1px solid red;
          }

          article {
            // outline: 1px solid red;
            color: #fff;

            .sub-title {
              font-size: 0.8em;
              font-family: "Lato", sans-serif;
              display: block;

            }

            h2 {
              color: $main-color;
              margin: 0 0 20px 0;
              font-size: 2em;


              &:after {
                content: '';
                width: 70px;
                height: 1px;
                background-color: #fff;
                display: block;
                margin-top: 30px;
              }
            }

            p {
              text-shadow:
                1px 1px 5px rgba(0, 0, 0, .6),
                1px 1px 10px rgba(0, 0, 0, .6),
                1px 1px 15px rgba(0, 0, 0, .6);
              line-height: 1.4em;

              span {
                color: $main-color;
              }
            }
          }

        }

        .b2 {
          width: 90%;
          max-width: 1600px;
          margin: 0 auto;
          background: #fff url('../images/house.png') no-repeat 0 bottom;
          background-size: contain;
          overflow: hidden;

          ///.b2-1~.b2-4
          &>div {
            width: 90%;
            margin: 0 auto 70px;

            article {
              h3 {
                font-family: 'Roboto Condensed', sans-serif;
                font-weight: 500;
                color: $sec-color;
                font-size: 25px;
                line-height: 1.2em;
                margin: 0 0 7% 0;
              }
            }
          }

          .title {
            background: url('../images/block2_titlebar.jpg') no-repeat center 0;
            background-size: cover;
            padding: 5% 0;
            text-align: center;
            font-family: "Lato", sans-serif;
            font-weight: normal;
            color: #fff;
            margin: 0 auto 30px;
            line-height: 1.2em;
          }

          .b2-1 {}

          .b2-2 {
            width: 100%;

            article {
              width: 90%;
              margin: 0 auto 0px;
            }

            section {
              background: url('../images/recycle_bg.png') no-repeat 0 0;
              background-size: contain;
              padding: 3% 0 7%;

              img {
                width: 70%;
                margin: 0 auto;
              }
            }
          }

          .b2-3 {}

          .b2-4 {
            width: 100%;

            article {
              width: 90%;
              margin: 0 auto 0px;

              ul {
                position: relative;
                list-style: none;
                padding: 0 0 60% 15px;

                li {
                  position: relative;

                  &:before {
                    content: '●';
                    color: $sec-color;
                    font-size: 6px;
                    position: absolute;
                    top: 2px;
                    left: -12px;
                  }
                }
              }
            }
          }
        }

        .b3 {
          background: #000 url('../images/block3_bg-m.jpg') 0 0;
          background-size: 100%;
          color: #fff;
          padding: 10% 0;
          overflow: hidden;

          section {
            width: 90%;
            margin: 0 auto;

            &:nth-child(1) {
              h2 {
                color: $main-color;
                margin: 0 0 20px 0;
                font-family: "Lato", sans-serif;
                font-weight: normal;

                &:after {
                  content: '';
                  width: 50px;
                  height: 1px;
                  background-color: #fff;
                  display: block;
                  margin-top: 30px;
                }
              }

              ul {
                position: relative;
                list-style: none;
                padding: 0 0 0% 15px;

                li {
                  margin-bottom: 15px;
                  position: relative;

                  &:before {
                    content: '●';
                    color: $main-color;
                    position: absolute;
                    font-size: 6px;
                    top: 2px;
                    left: -12px;
                  }
                }

                ul {
                  margin: 10px 0 20px;

                  li {
                    margin-bottom: 0px;
                    //position: relative;

                    &:before {
                      content: '●';
                      color: #fff;
                      //position: absolute;
                      font-size: 3px;
                      top: 2px;
                      left: -12px;
                    }
                  }
                }

              }
            }

            &:nth-child(2) {
              margin-top: 16%;

              h3 {
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 25px;
                margin: 0 0 20px 0;

                &:before {
                  content: '';
                  width: 5px;
                  height: 20px;
                  background-color: #fff;
                  display: inline-block;
                  margin-right: 7px;
                  position: relative;
                  top: 1px;
                }
              }

              table {
                border-collapse: collapse;
                width: 100%;

                tr {
                  border-bottom: 1px solid #fff;
                  transition: .3s;

                  &:nth-child(odd) {
                    background-color: rgba(255, 255, 255, .1);
                  }

                  &:hover {
                    background-color: $main-color;
                    transition: .3s;
                  }

                  th {
                    width: 40%;
                    padding: 15px 0 15px 5px;
                    text-align: left;
                  }

                  td {
                    padding: 15px 5px;
                  }
                }
              }
            }
          }
        }

        .b4 {
          padding: 10% 0;
          background-color: adjust-color($color: $main-color,
              $hue: calc(9% / 1%),
              $saturation: 33%,
              $lightness: -25%,
              $alpha: 1);

          .block-box {
            width: 90%;
            margin: 0 auto;
            max-width: 1600px;

            h2 {
              color: #fff;
              margin: 0 0 20px 0;
              font-family: "Lato", sans-serif;
              font-weight: normal;

              &:after {
                content: '';
                width: 50px;
                height: 1px;
                background-color: #fff;
                display: block;
                margin-top: 30px;
              }
            }

            section {

              &>a {
                display: block;
                text-decoration: none;
                width: 80%;
                margin: 0 auto 40px;

                &:last-child {
                  margin-bottom: 0;
                }

                dl {


                  dt {
                    color: #fff;
                    text-align: center;
                    font-family: 'Lato', sans-serif;
                    margin-bottom: 10px;
                  }

                  //fancy-box 的圖
                  dd {
                    padding-top: 123%;
                    background-position: center 0;
                    background-size: 100%;
                    margin: 0;
                    position: relative;
                    width: 100%;
                    background-color: #000;
                    transition: 1s;

                    &:before {
                      content: '';
                      border: 10px solid #fff;
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      top: 0;
                      left: 0;
                      pointer-events: none;
                      box-sizing: border-box;
                      transition: .3s;
                    }
                  }
                }

                &:hover {

                  dl {

                    //fancy-box 的圖
                    dd {
                      background-size: 120%;
                      background-origin: center center;
                      transition: 1s;

                      &:before {
                        // content: '';
                        border: 20px solid #fff;
                        width: 100%;
                        height: 100%;
                        transition: .3s;
                      }
                    }
                  }
                }
              }
            }
          }


        }

        .b5 {
          background: #000 url('../images/block5_bg.jpg') no-repeat 0 0;
          background-size: cover;
          color: #fff;
          padding: 10% 0;

          .block-box {
            width: 90%;
            margin: 0 auto;
            max-width: 1600px;

            &>section {
              margin-bottom: 50px;

              h2 {
                color: $main-color;
                margin: 0 0 20px 0;
                font-family: "Lato", sans-serif;
                font-weight: normal;

                &:after {
                  content: '';
                  width: 50px;
                  height: 1px;
                  background-color: #fff;
                  display: block;
                  margin-top: 30px;
                }
              }

              h3 {
                font-size: 1.3em;
                margin: 0 0 5px 0;
                font-weight: normal;
              }

              h4 {
                font-size: 1em;
                margin: 0 0 40px 0;
                font-weight: normal;
              }

              dl {
                display: flex;
                margin: 0 0 10px;
                font-size: .85em;
                color: #ccc;
                line-height: 1.3em;

                &:before {
                  content: '●';
                  display: block;
                  margin-right: 10px;
                  font-size: 3px;
                  color: #fff;
                }

                dt {
                  flex: 0 0 65px;
                }

                dd {
                  flex: 0 0 calc(100% - 65px);
                  margin: 0;
                  word-wrap: break-all;

                }
              }

            }

            .form_container {
              //_form.scss
            }
          }
        }
      }

      .googlemap {
        iframe {
          //filter: grayscale(100%);
          height: 400px;

        }
      }
    }
  }

  @include breakpoint(767) {
    .box {
      .main {
        .kv {

          //margin-top: -80px;
          // background: url('../images/kv.jpg') no-repeat center 0;
          // background-size: auto;
          // height: 100vh;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // font-family: 'Lato', sans-serif;
          &:before {
            // content: '';
            // position: fixed;
            // top: 0;
            // right: 0;
            // bottom: 0;
            // left: 0;
            background: #000 url('../images/kv.jpg') no-repeat 0 0;
            // background-size: 100%;
          }

          section {
            .logo2 {
              // width: 70%;
              // max-width: 400px;
              // margin: 0 auto;
              // filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .6));
            }

            p {
              // text-align: center;
              // color: #fff;
              font-size: 4vw;
            }
          }
        }

        .content {

          .b1 {
            background: #000 url('../images/block1_bg.jpg') no-repeat center 0;
            background-size: 100%;
            padding: 10% 0;

            .block-box {
              // width: 90%;
              // margin: 0 auto;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
            }

            section {
              flex: 0 0 45%;
              // width: 90%;
              margin: 0;
            }

            article {
              flex: 0 0 50%;
              // outline: 1px solid red;
              // color: #fff;

              .sub-title {
                // font-size: 0.8em;
                // font-family: "Lato", sans-serif;
              }

              h2 {
                // color: $main-color;
                // margin: 0 0 20px 0;
                // font-size: 2em;
              }

              p {
                span {
                  // color: $main-color;
                }
              }
            }

          }

          .b2 {
            // width: 90%;
            // max-width: 1600px;
            margin: -50px auto 0;

            // background: #fff url('../images/house.png') no-repeat 0 bottom;
            // background-size: contain;
            background-size: 55%;
            padding-bottom: 5%;

            ///.b2-1~.b2-4
            &>div {
              // width: 90%;
              margin: 0 auto 50px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;

              &:last-child {
                margin-bottom: 0;
              }

              article {
                flex: 0 0 50%;

                h3 {
                  // font-family: roboto;
                  // font-weight: 500;
                  // color: $sec-color;
                  // line-height: 1.2em;
                  // margin: 0 0 10px 0;
                  font-size: 25px;
                }
              }

              section {
                flex: 0 0 50%;
              }
            }

            .title {
              // background: url('../images/block2_titlebar.jpg') no-repeat center 0;
              // background-size: cover;
              padding: 3% 0;
              // text-align: center;
              // font-family: "Lato", sans-serif;
              // font-weight: normal;
              // color: #fff;
              margin-bottom: 70px;
              font-size: 30px;
            }

            .b2-1 {}

            .b2-2 {
              // width: 100%;

              article {
                // width: 90%;
                // margin: 0 auto 0px;
                flex: 0 0 43%;
                margin-right: 5%;
              }

              section {
                order: -1;
                // background: url('../images/recycle_bg.png') no-repeat 0 0;
                // background-size: contain;

                img {
                  // width: 80%;
                  // margin: 0 auto;
                }
              }
            }

            .b2-3 {}

            .b2-4 {
              // width: 100%;
              justify-content: flex-end;

              article {
                // width: 90%;
                flex: 0 0 45%;
                margin: 0;

                ul {
                  // position: relative;
                  // list-style: none;
                  padding: 0 0 0% 0px;
                  // display: flex;
                  // flex-wrap: wrap;

                  li {
                    // flex: 0 0 50%;
                    padding-left: 12px;
                  }

                  li:before {

                    // content: '●';
                    // color: $sec-color;
                    // font-size: 6px;
                    // position: relative;
                    // top: -3px;
                    left: 0px;
                  }

                }
              }
            }
          }

          .b3 {

            background: #000 url('../images/block3_bg.jpg') 0 0;
            background-size: cover;
            // color: #fff;
            padding: 5% 0;

            .block-box {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              // max-width: 1600px;
              margin: 0 auto;
            }

            section {
              // width: 90%;
              // margin: 0 auto;

              &:nth-child(1) {
                flex: 0 0 44%;
                margin: 0 1.5% 0 0;

                h2 {
                  // color: $main-color;
                  // margin: 0 0 20px 0;

                  &:after {
                    // content: '';
                    // width: 50px;
                    // height: 1px;
                    // background-color: #fff;
                    // display: block;
                    // margin-top: 30px;
                  }
                }

                ul {
                  // position: relative;
                  // list-style: none;
                  // padding: 0 0 0% 15px;

                  li {
                    // margin-bottom: 15px;
                    // position: relative;

                    &:before {
                      // content: '●';
                      // color: $main-color;
                      // position: absolute;
                      // font-size: 6px;
                      // top: 2px;
                      // left: -12px;
                    }
                  }

                  ul {
                    // margin: 10px 0 20px;

                    li {
                      // margin-bottom: 0px;

                      &:before {
                        content: '●';
                        // color: #fff;
                        // font-size: 3px;
                        // top: 2px;
                        // left: -12px;
                      }
                    }
                  }

                }
              }

              &:nth-child(2) {
                flex: 0 0 44%;
                margin: 75px 0 0 1.5%;

                h3 {
                  // font-family: 'Roboto Condensed', sans-serif;
                  // font-size: 25px;
                  // margin: 0 0 20px 0;

                  &:before {
                    // content: '';
                    // width: 5px;
                    // height: 20px;
                    // background-color: #fff;
                    // display: inline-block;
                    // margin-right: 7px;
                    // position: relative;
                    // top: 1px;
                  }
                }

                table {
                  // border-collapse: collapse;
                  // width: 100%;

                  tr {
                    // border-bottom: 1px solid #fff;
                    // transition: .3s;

                    &:nth-child(odd) {
                      // background-color: rgba(255, 255, 255, .1);
                    }

                    &:hover {
                      // background-color: $main-color;
                      // transition: .3s;
                    }

                    th {
                      // width: 40%;
                      // padding: 15px 0 15px 5px;
                      // text-align: left;
                    }

                    td {
                      // padding: 0 5px;
                    }
                  }
                }
              }
            }
          }

          .b4 {
            // padding: 10% 0;
            // background-color: adjust-color($color: $main-color,
            // $hue: calc(9% / 1%),
            // $saturation: 33%,
            // $lightness: -25%,
            // $alpha: 1);

            .block-box {
              // width: 90%;

              // margin: 0 auto;

              h2 {
                // color: #fff;
                // margin: 0 0 20px 0;
                // font-family: "Lato", sans-serif;
                // font-weight: normal;
                margin-bottom: 5%;

                &:after {
                  // content: '';
                  // width: 50px;
                  // height: 1px;
                  // background-color: #fff;
                  // display: block;
                  // margin-top: 30px;
                }
              }

              section {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                a {
                  flex: 0 0 23%;

                  dl {
                    width: 100%;
                    margin: 0 auto 0px;

                    &:last-child {
                      // margin-bottom: 0;
                    }

                    dt {
                      // color: #fff;
                      // text-align: center;
                      // font-family: 'Lato', sans-serif;
                      // margin-bottom: 10px;
                    }

                    //fancy-box 的圖
                    dd {
                      // padding-top: 123%;
                      // background-position: center 0;
                      // background-size: cover;
                      // margin: 0;
                      // position: relative;
                      // width: 100%;
                      // background-color: #000;

                      &:before {
                        content: '';
                        // border: 10px solid #fff;
                        // position: absolute;
                        // width: calc(100% - 10px - 10px);
                        // height: calc(100% - 10px - 10px);
                        // top: 0;
                        // left: 0;
                      }
                    }
                  }
                }
              }
            }


          }

          .b5 {
            background: #000 url('../images/block5_bg.jpg') no-repeat 0 0;
            background-size: cover;
            // color: #fff;
            padding: 5% 0;

            .block-box {
              // width: 90%;
              // margin: 0 auto;
              // max-width: 1600px;
              display: flex;
              flex-flow: wrap row;
              justify-content: space-between;

              section {
                flex: 0 0 48%;

                h2 {
                  // color: $main-color;
                  // margin: 0 0 20px 0;
                  // font-family: "Lato", sans-serif;
                  // font-weight: normal;

                  &:after {
                    // content: '';
                    // width: 50px;
                    // height: 1px;
                    // background-color: #fff;
                    // display: block;
                    // margin-top: 30px;
                  }
                }

                h3 {
                  // font-size: 1.3em;
                  // margin: 0 0 5px 0;
                }

                h4 {
                  // font-size: 1em;
                  // margin: 0 0 40px 0;
                }

                dl {
                  // display: flex;
                  // margin: 0 0 10px;
                  // font-size: .85em;
                  // color: #ccc;
                  // line-height: 1.3em;

                  &:before {
                    // content: '●';
                    // display: block;
                    // margin-right: 10px;
                    // font-size: 3px;
                    // color: #fff;
                  }

                  dt {
                    // flex: 0 0 65px;
                  }

                  dd {
                    // flex: 0 0 calc(100% - 65px);
                    // margin: 0;

                  }
                }

              }

              .form_container {
                //_form.scss
                flex: 0 0 48%;
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(960) {
    .box {
      font-size: 20px;
      line-height: 1.3em;

      .main {
        .kv {
          // background: url('../images/kv.jpg') no-repeat center 0;
          // background-size: cover;
          // height: 100vh;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // font-family: 'Lato', sans-serif;

          section {
            .logo2 {
              // width: 70%;
              // max-width: 400px;
              // margin: 0 auto;
              // filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .6));
            }

            p {
              // text-align: center;
              // color: #fff;
              font-size: 60px;
            }
          }
        }

        .content {

          .b1 {
            // background: #000 url('../images/block1_bg.jpg') no-repeat center 0;
            // background-size: 100%;
            // padding: 10% 0;

            .block-box {
              // width: 90%;
              // margin: 0 auto;
              // display: flex;
              // flex-wrap: wrap;
              // justify-content: space-between;
              // align-items: center;
            }

            section {
              // flex: 0 0 45%;
              // width: 90%;
              // margin: 0 auto;
            }

            article {
              // flex: 0 0 50%;
              // outline: 1px solid red;
              // color: #fff;

              .sub-title {
                // font-size: 0.8em;
                // font-family: "Lato", sans-serif;
              }

              h2 {
                // color: $main-color;
                // margin: 0 0 20px 0;
                // font-size: 2em;
              }

              p {
                span {
                  // color: $main-color;
                }
              }
            }

          }

          .b4 {
            // padding: 10% 0;
            // background-color: adjust-color($color: $main-color,
            // $hue: calc(9% / 1%),
            // $saturation: 33%,
            // $lightness: -25%,
            // $alpha: 1);

            .block-box {
              // width: 90%;

              // margin: 0 auto;

              h2 {
                // color: #fff;
                // margin: 0 0 20px 0;
                // font-family: "Lato", sans-serif;
                // font-weight: normal;
                margin-bottom: 5%;

                &:after {
                  // content: '';
                  width: 100px;
                  // height: 1px;
                  // background-color: #fff;
                  // display: block;
                  // margin-top: 30px;
                }
              }

              section {
                // display: flex;
                // flex-wrap: wrap;
                // justify-content: space-between;

                a {
                  // flex: 0 0 23%;

                  dl {
                    // width: 100%;
                    // margin: 0 auto 0px;

                    &:last-child {
                      // margin-bottom: 0;
                    }

                    dt {
                      // color: #fff;
                      // text-align: center;
                      // font-family: 'Lato', sans-serif;
                      // margin-bottom: 10px;
                    }

                    //fancy-box 的圖
                    dd {
                      // padding-top: 123%;
                      // background-position: center 0;
                      // background-size: cover;
                      // margin: 0;
                      // position: relative;
                      // width: 100%;
                      // background-color: #000;

                      &:before {
                        content: '';
                        // border: 10px solid #fff;
                        // position: absolute;
                        // width: calc(100% - 10px - 10px);
                        // height: calc(100% - 10px - 10px);
                        // top: 0;
                        // left: 0;
                      }
                    }
                  }
                }
              }
            }


          }

          .b5 {
            // background: #000 url('../images/block5_bg.jpg') 0 0;
            // background-size: cover;
            // color: #fff;
            // padding: 10% 0;

            .block-box {
              // width: 90%;
              // margin: 0 auto;
              // max-width: 1600px;
              // display: flex;
              // flex-flow: wrap row;
              // justify-content: space-between;

              section {
                flex: 0 0 29%;

                h2 {
                  // color: $main-color;
                  margin: 0 0 55px 0;
                  // font-family: "Lato", sans-serif;
                  // font-weight: normal;

                  &:after {
                    // content: '';
                    width: 100px;
                    // height: 1px;
                    // background-color: #fff;
                    // display: block;
                    // margin-top: 30px;
                  }
                }

                h3 {
                  font-size: 30px;
                  // margin: 0 0 5px 0;
                }

                h4 {
                  font-size: 18px;
                  // margin: 0 0 40px 0;
                }

                dl {
                  // display: flex;
                  // margin: 0 0 10px;
                  font-size: 16px;
                  // color: #ccc;
                  // line-height: 1.3em;

                  &:before {
                    // content: '●';
                    // display: block;
                    // margin-right: 10px;
                    // font-size: 3px;
                    // color: #fff;
                  }

                  dt {
                    flex: 0 0 80px;
                  }

                  dd {
                    flex: 0 0 calc(100% - 80px);
                    // margin: 0;

                  }
                }

              }

              .form_container {
                //_form.scss
                flex: 0 0 63%;
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(1280) {
    .box {
      font-size: 30px;
      line-height: 1.3em;

      .main {
        .kv {
          // background: url('../images/kv.jpg') no-repeat center 0;
          // background-size: cover;
          // height: 100vh;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // font-family: 'Lato', sans-serif;

          section {
            .logo2 {
              // width: 70%;
              // max-width: 400px;
              // margin: 0 auto;
              // filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .6));
            }

            p {
              // text-align: center;
              // color: #fff;
              // font-size: 60px;
            }
          }
        }

        .content {

          .b1 {
            // background: #000 url('../images/block1_bg.jpg') no-repeat center 0;
            // background-size: 100%;
            // padding: 10% 0;

            .block-box {
              // width: 90%;
              // margin: 0 auto;
              // display: flex;
              // flex-wrap: wrap;
              // justify-content: space-between;
              // align-items: center;
            }

            section {
              // flex: 0 0 45%;
              // width: 90%;
              // margin: 0 auto;
            }

            article {
              // flex: 0 0 50%;
              // outline: 1px solid red;
              // color: #fff;

              .sub-title {
                // font-size: 0.8em;
                // font-family: "Lato", sans-serif;
              }

              h2 {
                // color: $main-color;
                margin: 0 0 40px 0;

                // font-size: 2em;
                &:after {
                  // content: '';
                  width: 100px;
                  // height: 1px;
                  // background-color: #fff;
                  // display: block;
                  margin-top: 40px;
                }
              }

              p {
                span {
                  // color: $main-color;
                }
              }
            }

          }

          .b2 {
            // width: 90%;
            // max-width: 1600px;
            margin: -100px auto 0;

            // background: #fff url('../images/house.png') no-repeat 0 bottom;
            // background-size: contain;
            background-size: 52%;

            ///.b2-1~.b2-4
            &>div {
              // width: 90%;
              // margin: 0 auto 50px;
              // display: flex;
              // justify-content: space-between;
              // flex-wrap: wrap;

              article {
                // flex: 0 0 50%;


                h3 {
                  // font-family: roboto;
                  // font-weight: 500;
                  // color: $sec-color;
                  // line-height: 1.2em;
                  // margin: 0 0 10px 0;
                  font-size: 40px;
                }

                p,
                ul {
                  font-size: 20px;
                  line-height: 1.5em;
                }
              }

              section {
                // flex: 0 0 50%;
              }
            }

            .title {
              // background: url('../images/block2_titlebar.jpg') no-repeat center 0;
              // background-size: cover;
              // padding: 3% 0;
              // text-align: center;
              // font-family: "Lato", sans-serif;
              // font-weight: normal;
              // color: #fff;
              // margin-bottom: 70px;
              font-size: 45px;
            }

            .b2-1 {}

            .b2-2 {
              // width: 100%;

              article {
                // width: 90%;
                // margin: 0 auto 0px;
                // flex: 0 0 43%;
                // margin-right: 5%;
              }

              section {
                // order: -1;
                // background: url('../images/recycle_bg.png') no-repeat 0 0;
                // background-size: contain;

                img {
                  // width: 80%;
                  // margin: 0 auto;
                  margin: 0 0 0 18%;
                }
              }
            }

            .b2-3 {}

            .b2-4 {
              // width: 100%;
              // justify-content: flex-end;

              article {
                // width: 90%;
                flex: 0 0 48%;
                // margin: 0;

                ul {
                  // position: relative;
                  // list-style: none;
                  // padding: 0 0 0% 0px;
                  display: flex;
                  flex-wrap: wrap;

                  li {
                    flex: 0 0 calc(50% - 12px);
                  }

                  li:before {

                    // content: '●';
                    // color: $sec-color;
                    // font-size: 6px;
                    // position: relative;
                    // top: -3px;
                    // left: -7px;        
                  }

                }
              }
            }
          }

          .b3 {
            // background: #000 url('../images/block3_bg.jpg') 0 0;
            // background-size: 100%;
            // color: #fff;
            // padding: 10% 0;
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: center;
            font-size: 20px;

            section {
              // width: 90%;
              // margin: 0 auto;

              &:nth-child(1) {
                // flex: 0 0 44%;
                // outline: 1px solid red;
                // margin: 0 1.5% 0 0;

                h2 {
                  // color: $main-color;
                  margin: 0 0 40px 0;
                  font-size: 48px;

                  &:after {
                    // content: '';
                    width: 100px;
                    // height: 1px;
                    // background-color: #fff;
                    // display: block;
                    // margin-top: 30px;
                  }
                }

                ul {
                  // position: relative;
                  // list-style: none;
                  padding: 0 0 0% 30px;

                  li {
                    // margin-bottom: 15px;
                    // position: relative;

                    &:before {
                      // content: '●';
                      // color: $main-color;
                      // position: absolute;
                      font-size: 8px;
                      // top: 2px;
                      left: -17px;
                    }
                  }

                  ul {
                    // margin: 10px 0 20px;

                    li {
                      // margin-bottom: 0px;

                      &:before {
                        // content: '●';
                        // color: #fff;
                        // font-size: 3px;
                        // top: 2px;
                        // left: -12px;
                      }
                    }
                  }

                }
              }

              &:nth-child(2) {
                // flex: 0 0 44%;
                margin: 120px 0 0 1.5%;
                // outline: 1px solid red;

                h3 {
                  // font-family: 'Roboto Condensed', sans-serif;
                  font-size: 36px;
                  // margin: 0 0 20px 0;

                  &:before {
                    // content: '';
                    // width: 5px;
                    height: 27px;
                    // background-color: #fff;
                    // display: inline-block;
                    margin-right: 10px;
                    // position: relative;
                    // top: 1px;
                  }
                }

                table {
                  // border-collapse: collapse;
                  // width: 100%;

                  tr {
                    // border-bottom: 1px solid #fff;
                    // transition: .3s;

                    &:nth-child(odd) {
                      // background-color: rgba(255, 255, 255, .1);
                    }

                    &:hover {
                      // background-color: $main-color;
                      // transition: .3s;
                    }

                    th {
                      width: 324px;
                      // padding: 15px 0 15px 5px;
                      // text-align: left;
                    }

                    td {
                      // padding: 0 5px;
                    }
                  }
                }
              }
            }
          }

          .b4 {
            padding: 6% 0;
            // background-color: adjust-color($color: $main-color,
            // $hue: calc(9% / 1%),
            // $saturation: 33%,
            // $lightness: -25%,
            // $alpha: 1);

            .block-box {
              // width: 90%;
              // max-width: 1600px;
              // margin: 0 auto;

              h2 {
                // color: #fff;
                // margin: 0 0 20px 0;
                // font-family: "Lato", sans-serif;
                // font-weight: normal;

                &:after {
                  // content: '';
                  // width: 50px;
                  // height: 1px;
                  // background-color: #fff;
                  // display: block;
                  // margin-top: 30px;
                }
              }

              section {
                // display: flex;
                // flex-wrap: wrap;
                // justify-content: space-between;

                a {
                  // flex: 0 0 20%;

                  dl {
                    // width: 100%;
                    // margin: 0 auto 0px;

                    &:last-child {
                      // margin-bottom: 0;
                    }

                    dt {
                      // color: #fff;
                      // text-align: center;
                      // font-family: 'Lato', sans-serif;
                      // margin-bottom: 10px;
                    }

                    //fancy-box 的圖
                    dd {
                      // padding-top: 123%;
                      // background-position: center 0;
                      // background-size: cover;
                      // margin: 0;
                      // position: relative;
                      // width: 100%;
                      // background-color: #000;

                      &:before {
                        content: '';
                        // border: 10px solid #fff;
                        // position: absolute;
                        // width: calc(100% - 10px - 10px);
                        // height: calc(100% - 10px - 10px);
                        // top: 0;
                        // left: 0;
                      }
                    }
                  }
                }
              }
            }


          }
        }
      }
    }
  }

  @include breakpoint(1600) {
    .box {

      .main {
        .kv {
          // background: url('../images/kv.jpg') no-repeat center 0;
          // background-size: cover;
          // height: 100vh;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // font-family: 'Lato', sans-serif;

          section {
            .logo2 {
              // width: 70%;
              // max-width: 400px;
              // margin: 0 auto;
              // filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .6));
            }

            p {
              // text-align: center;
              // color: #fff;
              font-size: 60px;
            }
          }
        }

        .content {

          .b1 {
            // background: #000 url('../images/block1_bg.jpg') no-repeat center 0;
            // background-size: 100%;
            padding: 8% 0;

            .block-box {
              // width: 90%;
              // margin: 0 auto;
              // display: flex;
              // flex-wrap: wrap;
              // justify-content: space-between;
              justify-content: center;
              // align-items: center;
            }


            section {
              flex: 0 0 45%;
              margin-right: 3%;
              // width: 90%;
              // margin: 0 auto;
            }

            article {
              flex: 0 0 40%;
              margin-left: 3%;
              // outline: 1px solid red;
              // color: #fff;

              .sub-title {
                // font-size: 0.8em;
                // font-family: "Lato", sans-serif;
              }

              h2 {
                // color: $main-color;
                // margin: 0 0 20px 0;
                // font-size: 2em;
              }

              p {
                span {
                  // color: $main-color;
                }
              }
            }

          }

          .b2 {
            // width: 90%;
            // max-width: 1600px;
            // margin: -100px auto 0;

            // background: #fff url('../images/house.png') no-repeat 0 bottom;
            // background-size: contain;
            // background-size: 52%;

            ///.b2-1~.b2-4
            &>div {
              // width: 90%;
              // margin: 0 auto 50px;
              // display: flex;
              // justify-content: space-between;
              // flex-wrap: wrap;

              article {
                flex: 0 0 42%;


                h3 {
                  // font-family: roboto;
                  // font-weight: 500;
                  // color: $sec-color;
                  // line-height: 1.2em;
                  // margin: 0 0 10px 0;
                  font-size: 47px;
                }

                p,
                ul {
                  // font-size: 20px;
                  // line-height: 1.5em;
                }
              }

              section {
                // flex: 0 0 50%;
              }
            }

            .title {
              // background: url('../images/block2_titlebar.jpg') no-repeat center 0;
              // background-size: cover;
              padding: 5% 0;
              // text-align: center;
              // font-family: "Lato", sans-serif;
              // font-weight: normal;
              // color: #fff;
              // margin-bottom: 70px;
              font-size: 60px;
            }

            .b2-1 {
              article {
                margin-left: 5%;
              }
            }

            .b2-2 {
              // width: 100%;

              article {
                // width: 90%;
                // margin: 0 auto 0px;
                // flex: 0 0 43%;
                // margin-right: 5%;
              }

              section {
                // order: -1;
                // background: url('../images/recycle_bg.png') no-repeat 0 0;
                // background-size: contain;
                padding: 2% 0 4%;

                img {
                  width: 72%;
                  // margin: 0 auto;
                  // margin: 0 0 0 18%;
                }
              }
            }

            .b2-3 {
              article {
                margin-left: 5%;
              }
            }

            .b2-4 {
              // width: 100%;
              // justify-content: flex-end;

              article {
                // width: 90%;
                // flex: 0 0 48%;
                // margin: 0;

                ul {
                  // position: relative;
                  // list-style: none;
                  // padding: 0 0 0% 0px;
                  // display: flex;
                  // flex-wrap: wrap;

                  li {
                    flex: 0 0 40%;
                    margin-bottom: 10px;
                    padding-left: 23px;
                  }

                  li:before {

                    // content: '●';
                    // color: $sec-color;
                    font-size: 8px;
                    // position: relative;
                    // top: -3px;
                    left: 7px;
                  }

                }
              }
            }
          }

          .b3 {
            // background: #000 url('../images/block3_bg.jpg') 0 0;
            // background-size: 100%;
            // color: #fff;
            // padding: 10% 0;
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: center;
            // font-size: 20px;

            section {
              // width: 90%;
              // margin: 0 auto;

              &:nth-child(1) {
                flex: 0 0 45%;
                // outline: 1px solid red;
                // margin: 0 1.5% 0 0;

                h2 {
                  // color: $main-color;
                  // margin: 0 0 40px 0;
                  // font-size: 48px;

                  &:after {
                    // content: '';
                    // width: 100px;
                    // height: 1px;
                    // background-color: #fff;
                    // display: block;
                    // margin-top: 30px;
                  }
                }

                ul {
                  // position: relative;
                  // list-style: none;
                  // padding: 0 0 0% 30px;
                  margin-left: 10%;

                  li {
                    // margin-bottom: 15px;
                    // position: relative;

                    &:before {
                      // content: '●';
                      // color: $main-color;
                      // position: absolute;
                      // font-size: 8px;
                      // top: 2px;
                      // left: -17px;
                    }
                  }

                  ul {
                    // margin: 10px 0 20px;

                    li {
                      // margin-bottom: 0px;

                      &:before {
                        // content: '●';
                        // color: #fff;
                        // font-size: 3px;
                        // top: 2px;
                        // left: -12px;
                      }
                    }
                  }

                }
              }

              &:nth-child(2) {
                flex: 0 0 35%;
                // margin: 120px 0 0 1.5%;
                // outline: 1px solid red;

                h3 {
                  // font-family: 'Roboto Condensed', sans-serif;
                  // font-size: 36px;
                  // margin: 0 0 20px 0;

                  &:before {
                    // content: '';
                    // width: 5px;
                    // height: 27px;
                    // background-color: #fff;
                    // display: inline-block;
                    // margin-right: 10px;
                    // position: relative;
                    // top: 1px;
                  }
                }

                table {
                  // border-collapse: collapse;
                  // width: 100%;

                  tr {
                    // border-bottom: 1px solid #fff;
                    // transition: .3s;

                    &:nth-child(odd) {
                      // background-color: rgba(255, 255, 255, .1);
                    }

                    &:hover {
                      // background-color: $main-color;
                      // transition: .3s;
                    }

                    th {
                      // width: 324px;
                      padding: 25px 0 25px 5px;
                      // text-align: left;
                    }

                    td {
                      // padding: 0 5px;
                    }
                  }
                }
              }
            }
          }
        }

        .googlemap {
          iframe {
            // filter: grayscale(100%);
            height: 600px;

          }
        }
      }
    }
  }
}