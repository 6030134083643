@charset "utf-8";
@import "assistant.scss";
@import "normalize.scss";
@import "set.scss";
@import "var.scss";
@import "mixin";
@import "normalize";
@import "keyframes";
@import "component.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&family=Rowdies:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@500;600;700&family=Rowdies:wght@700&display=swap');

.fancybox__container {
  z-index: 9999;
}

html,
body {
  font-family: "蘭亭黑", "Noto Sans TC", "微軟正黑體",
    'Arial', serif;
  color: #666;
  font-size: 16px;
  line-height: 1.5em;
  //background-color: rgb(237, 200, 200);
  //background-color: #000;

}

a {
  //color: #666;
}

p {
  //font-weight: bold;
  margin: 0 0 16px 0;
}

img {
  width: 100%;
  display: block;
}

.wrap {


  .box {


    .main {}
  }

  @include breakpoint(767) {
    // padding-top: 120px;
    padding-top: 0;

    p {
      //font-size: 22px;
    }

    .kv {}

    .box {}
  }

  @include breakpoint(960) {
    //background-color: rgb(171, 105, 183);
  }

  @include breakpoint(1280) {
    //background-color: rgb(110, 174, 202);
  }
}