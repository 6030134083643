@charset "utf-8";
@import "mixin";
@import "var.scss";

.main {}

.wrap {

  .form_container {
    //background: #fff url(../images/bg2.jpg);
    // background: url('../images/form-bg.jpg') no-repeat center top;
    // background-size: cover;
    padding: 0px 0 30px;
    font-family: "Noto Sans TC", "微軟正黑體", 'Arial', serif;

    ::placeholder {
      //
    }


    .form_body {
      // width: 90%;
      max-width: 960px;
      margin: 0px auto;
      box-sizing: border-box;
      //padding: 30px;
      position: relative;
      color: #444;

      form {

        //兩個一組的input
        &>section {
          margin-bottom: 10px;
        }
      }

      .title {
        font-family: 'Noto Serif TC', serif, "Noto Sans TC", "微軟正黑體", 'Arial', serif;
        // text-align: center;
        position: relative;
        // width: 70%;
        margin: 0 0 15px;
        font-size: 23px;
        font-weight: bold;

        img {
          //width: 70%;
        }
      }

      .flex {

        // display: flex;
        // flex-flow: wrap row;
        // justify-content: space-between;
        &>div {
          // flex: 0 0 48%;
          margin-bottom: 10px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .item-name {
            display: block;
            padding: 5px 0 0 5px;
          }
        }
      }

      //單獨給email的flex, 因為它只有自己一組,不適用flex
      .flex2 {
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      span {
        //display: inline-block;
        // margin-bottom: 10px;
        flex: 0 0 130px;
        color: #fff;
        display: block;
        margin-bottom: 5px;

        &:after {
          content: '*';
          color: #f00;
          margin-left: 5px;
        }
      }

      option {
        // color: #fff;
        //background-color: #172b48;
      }

      button {
        display: block;
        margin: 20px auto;
        padding: 10px 0px;
        width: 100%;
        // box-sizing: border-box;
        background-color: $main-color;
        border: 0;
        color: #fff;
        cursor: pointer;
      }

      input,
      select,
      textarea {
        flex: 0 0 calc(100% - 140px);
        outline: none;
        display: block;
        width: 100%;
        //margin: 0 auto 0px;
        padding: 0px 0px;
        box-sizing: border-box;
        //background-color: transparent;
        background-color: rgba(0, 0, 0, .5);

        //color: #000;
        border: 1px solid #444;
        //border-bottom: 1px solid rgba(white, .5);
        resize: none;
        letter-spacing: 1px;
        border-radius: 0;
        color: #fff;

        &:focus {
          border: 1px solid $main-color;
        }
      }

      textarea {
        padding: 4px 0 0 4px;
      }

      input[type='text'],
      select {
        height: 40px;
        //padding: 0 0 0 4px;
      }

      select {}
    }

  }

  @include breakpoint(767) {
    .form_container {
      // background: url('../images/form-bg.jpg') no-repeat center top;
      // padding: 100px 0;
      //padding: 70px 0 45px;
      padding-bottom: 0;

      ::placeholder {
        // color: #fff;
      }

      .form_body {

        // width: 90%;
        // max-width: 780px;
        // margin: 100px auto 0;
        // box-sizing: border-box;
        // border: 10px solid #fff;
        // border-top: 0;
        // position: relative;
        form {

          &>section {
            margin-bottom: 0px;
          }
        }

        .title {
          // text-align: center;
          // background-color: #f00;
          // position: relative;
          //top: -45px;
          // width: 50%;
          // margin-bottom: 50px;

          img {
            // width: 270px;
          }
        }

        .flex {
          display: flex;
          flex-flow: wrap row;
          justify-content: space-between;

          &>div {
            display: block;
            flex: 0 0 48.5%;
          }
        }

        //單獨給email的flex, 因為它只有自己一組,不適用flex
        .flex2 {
          margin-bottom: 10px;
          display: block;
          // flex-wrap: wrap;
        }

        span {
          // font-size: 20px;
        }

        option {
          // color: #fff;
          // background-//
        }

        button {
          // display: block;
          //width: 150px;
          // margin: 20px auto;
          // margin-top: 4%;
          //padding: 15px 0px;
          // max-width: 250px;
          // box-sizing: border-box;
          // background-color: #172b48;
          // border: 1px solid rgba(white, .5);
          // color: #fff;
          // letter-spacing: 1px;
          // font-size: 28px;
          margin: 20px 0 0;
        }

        input,
        select,
        textarea {
          // font-size: 20px;
          // display: block;
          // width: 80%;
          // margin: 0 auto 5px;
          // padding: 16px 0px;
          // box-sizing: border-box;
          // background-color: transparent;
          // color: #fff;
          // border: 0;
          // border-bottom: 1px solid rgba(white, .5);
          // resize: none;
        }

        textarea {
          // padding-left: 4px;
          height: 100px;
        }

        input[type='text'],
        select {
          // height: 50px;
          // padding: 0 0 0 4px;
        }

        label.privacy {
          // font-size: 20px;
        }
      }

    }
  }

  @include breakpoint(960) {
    .form_container {
      // background: url('../images/form-bg.jpg') no-repeat center top;
      // padding: 100px 0;
      padding: 0px 0 45px;

      ::placeholder {
        // color: #fff;
      }

      .form_body {
        // width: 90%;
        // max-width: 780px;
        // margin: 100px auto 0;
        // box-sizing: border-box;
        // border: 10px solid #fff;
        // border-top: 0;
        // position: relative;

        .title {
          // text-align: center;
          // background-color: #f00;
          // position: relative;
          //top: -45px;
          width: 50%;
          margin-bottom: 25px;
          font-size: 30px;

          img {
            // width: 270px;
          }
        }

        .flex {
          // display: flex;
          // flex-flow: wrap row;
          // justify-content: space-between;

          &>div {
            // flex: 0 0 48%;
          }
        }

        span {
          font-size: 20px;
        }

        option {
          // color: #fff;
          // background-//
        }

        button {
          // display: block;
          // width: 80%;
          // margin: 20px auto;
          margin-top: 4%;
          // padding: 15px 40px;
          // max-width: 250px;
          // box-sizing: border-box;
          // background-color: #172b48;
          // border: 1px solid rgba(white, .5);
          // color: #fff;
          letter-spacing: 1px;
          font-size: 21px;
        }

        input,
        select,
        textarea {
          font-size: 21px;
          // display: block;
          // width: 80%;
          // margin: 0 auto 5px;
          // padding: 16px 0px;
          // box-sizing: border-box;
          // background-color: transparent;
          // color: #fff;
          // border: 0;
          // border-bottom: 1px solid rgba(white, .5);
          // resize: none;
        }

        textarea {
          padding-left: 4px;
        }

        input[type='text'],
        select {
          height: 50px;
          padding: 0 0 0 4px;
        }

        label.privacy {
          font-size: 20px;
        }
      }

    }
  }

  @include breakpoint(1280) {
    .form_container {
      // background: url('../images/form-bg.jpg') no-repeat center top;
      // padding: 100px 0;
      // padding: 70px 0 45px;
      padding: 0;

      ::placeholder {
        // color: #fff;
      }

      .form_body {
        // width: 90%;
        // max-width: 780px;
        // margin: 100px auto 0;
        // box-sizing: border-box;
        // border: 10px solid #fff;
        // border-top: 0;
        // position: relative;

        .title {
          // text-align: center;
          // background-color: #f00;
          // position: relative;
          //top: -45px;
          // width: 50%;
          //margin-bottom: 25px;
          font-size: 42px;


          img {
            // width: 270px;
          }
        }

        .flex {
          // display: flex;
          // flex-flow: wrap row;
          // justify-content: space-between;

          &>div {
            // flex: 0 0 48%;
          }
        }

        span {
          // font-size: 20px;
        }

        option {
          // color: #fff;
          // background-//
        }

        button {
          // display: block;
          // width: 80%;
          // margin: 20px auto;
          // margin-top: 4%;
          // padding: 25px 0px;
          // max-width: 250px;
          // box-sizing: border-box;
          // background-color: #172b48;
          // border: 1px solid rgba(white, .5);
          // color: #fff;
          // letter-spacing: 1px;
          font-size: 28px;
        }

        input,
        select,
        textarea {
          // font-size: 20px;
          // display: block;
          // width: 80%;
          // margin: 0 auto 5px;
          // padding: 16px 0px;
          // box-sizing: border-box;
          // background-color: transparent;
          // color: #fff;
          // border: 0;
          // border-bottom: 1px solid rgba(white, .5);
          // resize: none;
        }

        textarea {
          // padding-left: 4px;
          height: 200px;
        }

        input[type='text'],
        select {
          // height: 50px;
          // padding: 0 0 0 4px;
        }

        label.privacy {
          // font-size: 20px;
        }
      }

    }
  }
}